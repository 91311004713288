import PropTypes from 'prop-types';
import React from 'react';
import { PATHS } from '/src/globals';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import { Link } from '/src/components/Link';
import { Router } from '@reach/router';
import {
  TransitionFade,
} from '/src/components/animators';

import { METADATA } from '/src/globals';
import InitialContent from '/src/page-sections/demo/client-side-routing/InitialContent';
import ExampleComponent1 from '/src/page-sections/demo/client-side-routing/ExampleComponent1';
import ExampleComponent2 from '/src/page-sections/demo/client-side-routing/ExampleComponent2';
import DemoNavigation from '/src/collections/DemoNavigation';
import PageWrapper from '../../components/PageWrapper';

//////////////////////////

const ExampleComponent3 = React.lazy( () => import("/src/page-sections/demo/client-side-routing/ExampleComponent3") );
const ExampleComponent4 = React.lazy( () => import("/src/page-sections/demo/client-side-routing/ExampleComponent4") );

const LazyComponent = ({ Component, ...props }) => (
  <React.Suspense fallback={'<p>Loading...</p>'}>
    <Component {...props} />
  </React.Suspense>
);

//////////////////////////

const propTypes = {
  theme: PropTypes.object,
};

const ClientSideRouting = (props) => {

  return ( <>

    <Helmet>
      <title>Client-Side Routing{METADATA.titleBarPostfix}</title>
      <meta name="description" content="This page is about..." />
    </Helmet>


    <PageWrapper>
    
      <Typography variant="h1">
        Client-Side Routing
      </Typography>
      
      <TransitionFade>
        <Typography variant="h2">
          Change content with links:
        </Typography>
      </TransitionFade>

      <Typography variant="body1">
        <Link
          to = {PATHS.clientSideRouting + "/route-1"}
          keepScrollPosition
          >
          Route 1
        </Link>
        {" | "}
        <Link
          to = {PATHS.clientSideRouting + "/route-2"}
          keepScrollPosition
          >
          Route 2
        </Link>
        {" | "}
        <Link
          to = {PATHS.clientSideRouting + "/route-2/hello"}
          keepScrollPosition
          >
          Route 2 (with Param)
        </Link>
        {" | "}
        <Link
          to = {PATHS.clientSideRouting + "/route-3"}
          keepScrollPosition
          >
          Route 3 (Lazy)
        </Link>
        {" | "}
        <Link
          to = {PATHS.clientSideRouting + "/route-4"}
          keepScrollPosition
          >
          Route 4 (Lazy)
        </Link>
      </Typography>
      
      <Router basepath="/demo/client-side-routing.html">
        <ExampleComponent1 path="/route-1" />
        <ExampleComponent2 path="/route-2/"/>
        <ExampleComponent2 path="/route-2/:text"/>
        <LazyComponent Component={ExampleComponent3} path="/route-3" />
        <LazyComponent Component={ExampleComponent4} path="/route-4" />
        <InitialContent default/>
      </Router>


      <Typography variant="h2">
        Description
      </Typography>
      <Typography variant="body1">
        By default, Gatsby creates static pages, however, client-side routing is also possible. The client side route urls must be defined in gatsby-config.js as patterns.
      </Typography>
      <Typography variant="body1">
        The layout component that controls the header also containers a Router in it so that the title in the header responds to the routing. This is limited to this page only in the demo but more complex patterns can be achieved though using matchPath in gatsby-node.js.<br/>
        Note: If different layouts on different groups of pages are desired, another alternative may be switching between multiple root layout components as described in the layout component documentation.
      </Typography>
      <ul>
        <Typography variant="body1">
          <li><Link to="https://www.gatsbyjs.com/docs/how-to/routing/client-only-routes-and-user-authentication/#handling-client-only-routes-with-gatsby">Gatsby Guide</Link></li>
          <li><Link to="https://reach.tech/router/">Reach Router Documentation</Link></li>
        </Typography>
      </ul>


      <Typography variant="h2">
        Troubleshooting
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            Ensure your basepath is set correctly to include the full path beyond the core url, ie. folders. etc
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            Ensure the same path is set up in gatsby-config.js for the gatsby-plugin-create-client-paths options (pattern with asterisk at the end)
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            The whole page reloads even if the base page doesn't change. This may have effects on your setup.<br/>
            One effect of this is that by default, Gatsby will scroll the page to the top on route change. To prevent this, pass a keepScrollPosition flag to the Link component.
          </Typography>
        </li>
      </ul>


      <Typography variant="h2">
        Known Issues
      </Typography>
      <ul>
        <li>
          <Typography variant="body1">
            When running on S3 and navigating to directly to a URL (a tag or pasting into the search bar), or, simply refreshing the page, Gatsby will first show the 404 page for an instant before redirecting to the correct page. The theme's styling will be kept from the 404 page, so you will notice that the paragraph text on this page will be erroneously centred.<br/>
            This also causes the page to return a 403 error status even though it visibly does load (after a split second). As well as the above styling issue, this may create issues for search engines crawling from external websites. It shouldn't impact internal links because Gatsby prefetches links different and no 403 occurs.<br/>
            This likely requires solving on a project by project basis as <Link to="https://stackoverflow.com/a/52770491/4522623">described here</Link>.
            The styling issue is <Link to="https://github.com/gatsbyjs/gatsby/issues/17891">described here</Link>.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            If using a page transition with AniLink, because the base page might be the same, the page may be replaced a splitsecond before the transition occurs.
          </Typography>
        </li>
      </ul>


      <DemoNavigation/>

    </PageWrapper>



  </> )

}

ClientSideRouting.propTypes = propTypes;
export default ClientSideRouting;

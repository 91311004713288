import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
  TransitionZoomIn,
} from '/src/components/animators';
import { Grid } from '@material-ui/core';

//////////////////////////

const ExampleComponent2 = props => {

  return ( <>

    <Grid
      container
      justify = "center"
      >

      <Grid item xs={6} sm={3} alignItems="center">
        <TransitionZoomIn delay="0" duration="1000">
          <Typography variant="h3">
          {props.text || "Five"}
          </Typography>
        </TransitionZoomIn>
      </Grid>
      <Grid item xs={6} sm={3} alignItems="center">
        <TransitionZoomIn delay="200" duration="1000">
          <Typography variant="h3">
          {props.text || "Six"}
          </Typography>
        </TransitionZoomIn>
      </Grid>
      <Grid item xs={6} sm={3} alignItems="center">
        <TransitionZoomIn delay="400" duration="1000">
          <Typography variant="h3">
          {props.text || "Seven"}
          </Typography>
        </TransitionZoomIn>
      </Grid>
      <Grid item xs={6} sm={3} alignItems="center">
        <TransitionZoomIn delay="600" duration="1000">
          <Typography variant="h3">
          {props.text || "Eight"}
          </Typography>
        </TransitionZoomIn>
      </Grid>

    </Grid>

  </> );
};

export default ExampleComponent2;

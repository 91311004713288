import React from 'react';
import Typography from '@material-ui/core/Typography';

//////////////////////////

const InitialContent = props => {

  return ( <>

    <Typography variant="body1">
      This is a component that shows up when the page is passed no URL parameters.<br/>
      Try adding "/route-1" or "/route-2" to this pages URL - or click on the links above.
    </Typography>

  </> );
};

export default InitialContent;
